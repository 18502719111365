import React, {Component} from "react";

class CDelim extends Component {

    render() {
        return (
            <p className="break-delim"/>
        )
    }
}

export default CDelim;